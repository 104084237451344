import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
  useTheme,
  Modal,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { FormattedMessage } from "react-intl";
import { loginService } from "../../services/auth.service";
import { loginSchema } from "../../services/form.service";
import {
  getItem,
  removeItem,
  setItem,
} from "../../services/localStorage.service";
import { configEnv } from "../../configs/config-env";
import { modalStyle } from "../../consts/styles";
import Error1Modal from "../../components/modals/Error1Modal";

type LoginInput = TypeOf<typeof loginSchema>;

const LoginPage = () => {
  const location = useLocation();

  const [openSuccess, setSuccessOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  // process
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { register, formState, handleSubmit } = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const { isValid, isDirty, errors } = formState;

  const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
    try {
      handleLoading();
      const loginResp = await loginService(values);

      const {
        access_token,
        refresh_token,
        season_start_date,
        season_end_date,
        first_period_start_date,
        second_period_start_date,
        second_period_end_date,
      } = loginResp.data;


      const session = { access_token, refresh_token };
      const global = {
        season_start_date,
        season_end_date,
        first_period_start_date,
        second_period_start_date,
        second_period_end_date,
      };

      setItem("session", JSON.stringify(session));
      setItem("global", JSON.stringify(global));

      handleSuccessOpen();
      setTimeout(() => {
        handleSuccessClose();
        navigate("/home");
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (location?.state?.logOut) {
      setTimeout(() => {
        removeItem("session");
      }, 1000);

      return;
    }
    const session = getItem("session");
    if (session) navigate(`/home`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (message) handleErrorOpen();
  }, [message]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "100px",
        paddingTop: "35px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "17px",
        }}
      >
        <img src="/images/logo.png" alt="logo" width={75} height={100} />
      </div>

      <Container
        component="main"
        maxWidth={matches ? "md" : "sm"}
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            padding: "40px 30px",
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: matches ? 0 : 3,
            flexDirection: matches ? "row" : "column",
            justifyContent: matches ? "space-around" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              width: matches ? "280px" : "100%",
            }}
          >
            <Typography align="center" variant="h4" color="ktgGreen.main">
              <FormattedMessage id="app.name" />
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography align="center" variant="body1" color="common.black">
                <FormattedMessage id="login.member.m1" />
              </Typography>

              <Typography align="center" variant="body1" color="common.black">
                <FormattedMessage id="login.member.m2" />
              </Typography>
            </Box>

            <Button
              color="blue"
              variant="contained"
              component={RouterLink}
              to="/register"
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="apply.register" />
              </Typography>
            </Button>

            <Box
              sx={{ textDecoration: "none" }}
              component={RouterLink}
              to="/anonymous-faq"
            >
              <Typography align="center" variant="body1" color="cgrey.main">
                คำถามที่พบบ่อย
              </Typography>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ color: "#717171", borderRightWidth: 2 }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              width: matches ? "280px" : "100%",
            }}
          >
            <Typography align="center" variant="body1" color="common.black">
              <FormattedMessage id="login.member.m3" />
            </Typography>

            <Typography align="center" variant="h4GdBlue">
              <FormattedMessage id="signin" />
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{
                gap: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                color="lightGrey"
                fullWidth
                label="ชื่อผู้ใช้ หรือ เลขบัตรประชาชน"
                error={!!errors["username"]}
                {...register("username")}
              />

              <TextField
                color="lightGrey"
                fullWidth
                label="รหัสผ่าน"
                type="password"
                error={!!errors["password"]}
                {...register("password")}
              />

              <Button
                color="green"
                variant="contained"
                type="submit"
                disabled={!isDirty || !isValid || loading}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="signin" />
                </Typography>
              </Button>

              <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                <Typography
                  variant="body1"
                  color="cgrey.main"
                  component={RouterLink}
                  to="/forgot-password"
                  sx={{ textDecoration: "none" }}
                >
                  <FormattedMessage id="forgotpassword" />
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "#717171", borderRightWidth: 2 }}
                />
                <Typography
                  variant="body1"
                  color="cgrey.main"
                  component="a"
                  href={`${configEnv.admin_url}`}
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                >
                  <FormattedMessage id="foradmin" />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 340,
            bgcolor: "background.paper",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography align="center">
            <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="ktgGreen" />
          </Typography>

          <Typography
            align="center"
            variant="h4"
            color="ktgGreen.main"
            marginTop="15px"
          >
            <FormattedMessage id="login.member.success" />
          </Typography>
        </Box>
      </Modal>

      <Modal keepMounted open={openError}>
        <Box sx={modalStyle} width={340}>
          <Error1Modal
            title={message}
            subtitle={``}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default LoginPage;

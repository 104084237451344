import {
  Box,
  Container,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Button,
  FormHelperText,
  Modal,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { TypeOf } from "zod";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect, useState, ChangeEvent, useMemo, useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import Resizer from "react-image-file-resizer";
import "dayjs/locale/th";
import {
  provinceService,
  districtService,
  subdistrictService,
  postcodeService,
  memberTypeService,
} from "../../services/master-data.service";
import { uploadTempService } from "../../services/upload.service";
import {
  registerService,
  Register,
  verifyUsernameService,
  verifyIdentificationNumber,
  pdapService,
} from "../../services/auth.service";

import { registerSchema } from "../../services/form.service";
import PdapContent from "../../components/PdapContent";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import th from "dayjs/locale/th";
import { FormattedMessage } from "react-intl";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { getCroppedImg } from "../../utils/cropper.utils";
import { modalStyle } from "../../consts/styles";
import RegisterSuccessModal from "../../components/modals/RegisterSuccessModal";
import PdpaModal from "../../components/modals/PdpaModal";
import { patchFiitPadpaService } from "../../services/fiit.service";
import ErrorModal from "../../components/modals/ErrorModal";

class OverwriteAdapterDayjs extends AdapterDayjs {
  format = (date: any, formatKey: any) => {
    switch (formatKey) {
      case "dayOfMonth":
        return date.$D;
      case "monthAndYear":
        return dayjs(date).locale("th").format("DD MMMM BBBB");
      case "year":
        return dayjs(date).format("BBBB");
      case "monthShort":
        return dayjs(date).format("MMM");
      default:
        return "";
    }
  };
}

type RegisterInput = TypeOf<typeof registerSchema>;

const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter
  const inviteBy = queryParams.get("inviteBy") as string;

  const [openSuccess, setSuccesOpen] = useState(false);
  const [openCropDialog, setCropDialogOpen] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  const [openPdpa, setPdpaOpen] = useState(false);
  const [openFiitPdpa, setFiitPdpaOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [, setReaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptFiitPdpa, setAcceptFiitPdpa] = useState<boolean>(false);
  const [consent, setConsent] = useState("");

  // cropper
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  // master data
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);
  const [postcodes, setPostcodes] = useState([]);
  const [gender, setGender] = useState<string>("");
  const [memberType, setMemberType] = useState([]);
  // file upload
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();

  const [date, setDate] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [moo, setMoo] = useState("");

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => {
    setSuccesOpen(false);
  };
  const handleFiitPdpaOpen = () => {
    setFiitPdpaOpen(true);
    handleSuccessClose();
  };
  const handleFiitPdpaClose = () => {
    setFiitPdpaOpen(false);
    navigate(`/login`);
  };
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage("");
  };
  const handleCropDialogOpen = () => setCropDialogOpen(true);
  const handleCropDialogClose = () => setCropDialogOpen(false);
  const handlePdpaOpen = () => {
    pdapService().then((resp: any) => {
      const { pdpa } = resp.data;
      setConsent(pdpa);
      setPdpaOpen(true);
    });
  };
  const handlePdpaClose = () => setPdpaOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const handleAcceptFiitPdpaSubmit = async () => {
    try {
      handleLoading();
      const { username } = getValues();
      await patchFiitPadpaService({ username: username });
      setTimeout(() => {
        navigate("/connect-fiit", {
          state: {
            username: username, // username
          },
          replace: true,
        });
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      Math.ceil(e.target.scrollHeight - e.target.scrollTop) <=
      e.target.clientHeight + 10;

    if (bottom) setReaded(bottom);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const FormStyled = {
    display: "flex",
    flexDirection: matches ? "row" : "column",
    justifyContent: "space-between",
    gap: 3,
  };

  const FormIStyled = {
    display: "flex",
    flexDirection: matches ? "row" : "column",
    justifyContent: matches ? "" : "space-between",
    gap: 3,
  };

  const {
    register,
    formState,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const { isValid, isDirty, errors } = formState;

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
    try {
      handleLoading();
      setPdpaOpen(false);

      let temp_avatar_filename: any = "";

      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        const response = await uploadTempAvatar(formdata);
        temp_avatar_filename = response.filename;
      }

      const body: Register = {
        ...values,
        height: parseInt(values.height),
        weight: parseInt(values.weight),
        current_subdistrict_id: parseInt(values.current_subdistrict_id),
        current_district_id: parseInt(values.current_district_id),
        current_province_id: parseInt(values.current_province_id),
        date_of_birth: date,
        marriage_status: "",
        type_id: parseInt(values.type_id),
        gender: gender,
        temp_avatar_filename: temp_avatar_filename,
        invited_by: parseInt(inviteBy),
      };

      await registerService(body);
      handleLoaded();
      handleSuccessOpen();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  const getProvinces = async () => {
    const response = await provinceService();
    setProvinces(response.data);
  };

  const getDistricts = async (provinceId: string) => {
    const response = await districtService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await subdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const getPostcodes = async (subdistrictId: any) => {
    const response = await postcodeService(subdistrictId);
    setPostcodes(response.data);
  };

  const uploadTempAvatar = async (formdata: FormData) => {
    const response = await uploadTempService(formdata);
    return response.data;
  };

  const getMemberType = async () => {
    const response = await memberTypeService();
    setMemberType(response.data);
  };

  const verifyUsername = async (event: any) => {
    const value = event.target.value;
    if (value.length < 6 || value.length > 16) {
      setError("username", {
        type: "custom",
        message: "ชื่อผู้ใช้ต้องมีความยาว 6-16 ตัวอักษร",
      });
      return;
    }
    const response = await verifyUsernameService(value);
    const data = response.data;
    if (!data.is_available) {
      setError("username", {
        type: "custom",
        message: "ชื่อผู้ใช้นี้ มีอยู่ในระบบแล้ว",
      });
      return;
    }
    clearErrors("username");
  };

  const validateCitizenId = async (event: any) => {
    const value = event.target.value;
    if (value.substring(0, 1) === 0 || value.length !== 13) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรผิด",
      });
      return;
    } else {
      let sum = 0;
      for (var i = 0; i < value.length - 1; i++) {
        sum += parseFloat(value.charAt(i)) * (13 - i);
      }

      if ((11 - (sum % 11)) % 10 !== parseFloat(value.charAt(12))) {
        setError("identification_number", {
          type: "custom",
          message: "เลขบัตรผิด",
        });
        return;
      }
    }

    const response = await verifyIdentificationNumber({
      identification_number: value,
    });

    if (!response.data.is_available) {
      setError("identification_number", {
        type: "custom",
        message: "เลขบัตรนี้ มีอยู่ในระบบแล้ว",
      });
      return;
    }

    clearErrors("identification_number");
  };

  const handlePhoneNoChange = (event: any) => {
    const result = event.target.value.replace(/\D/g, "");
    if (result.length !== 10) {
      setError("phone_no", {
        type: "custom",
        message: "เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร",
      });
    } else {
      clearErrors("phone_no");
    }
    setValue("phone_no", result);
  };

  const debouncedUsernameHandler = useMemo(
    () => debounce(verifyUsername, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const debouncedIdNumberHandler = useMemo(
    () => debounce(validateCitizenId, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("current_district_id", "");
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setDistrics([]);
    setSubdistricts([]);
    setPostcodes([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setSubdistricts([]);
    setPostcodes([]);
    getSubdistricts(event.target.value);
  };

  const handleSubdistrictsChange = (event: SelectChangeEvent) => {
    setValue("current_postcode", "");

    setPostcodes([]);
    getPostcodes(event.target.value);
  };

  const handleWeightChange = (event: any) => {
    let result = event.target.value.replace(/\D/g, "");
    if (parseInt(result) > 200 || parseInt(result) < 10) {
      setError("weight", {
        type: "custom",
        message: "น้ำหนัก จำกัดช่วง 10-200 กก.",
      });
    } else {
      clearErrors("weight");
    }
    setWeight(result);
  };

  const handleHeightChange = (event: any) => {
    let result = event.target.value.replace(/\D/g, "");
    if (parseInt(result) > 250 || parseInt(result) < 90) {
      setError("height", {
        type: "custom",
        message: "ส่วนสูง จำกัด 90-250 ซม.",
      });
    } else {
      clearErrors("height");
    }
    setHeight(result);
  };

  const handleMooChange = (event: any) => {
    const result = event.target.value.replace(/\D/g, "");
    setMoo(result);
  };

  const handleGenderChange = (event: any) => {
    const result = event.target.value;
    setGender(result);
  };

  const handleAcceptFiitPdpaChange = (event: any) => {
    setAcceptFiitPdpa(acceptFiitPdpa ? false : true);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    // set zoom defualt crop
    setZoom(1);

    if (file) {
      setImage(URL.createObjectURL(file));
      handleCropDialogOpen();
    }
  };

  const acceptCropAvatar = async () => {
    const { url, blob }: any = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImage(url);

    const file = new File([blob], "name");
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (file: any) => {
        console.log(file);
        setFile(file);
      },
      "file"
    );
    handleCropDialogClose();
  };

  useEffect(() => {
    getProvinces();
    getMemberType();
  }, []);

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "100px",
        paddingTop: "36px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "17px",
        }}
      >
        <img src="/images/logo.png" alt="logo" width={75} height={100} />
      </Box>

      <Container
        component="main"
        maxWidth={matches ? "md" : "sm"}
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            display: "flex",
            padding: "40px 30px",
            borderRadius: "10px",
            background: "#FFFFFF",
            gap: matches ? 0 : 3,
            flexDirection: matches ? "row" : "column",
            justifyContent: matches ? "space-around" : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: 1,
            }}
          >
            <Typography align="center" variant="h3" color="ktgGreen.main">
              <FormattedMessage id="register" />
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: 120,
                  height: 120,
                  position: "relative",
                }}
              >
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "4px",
                      boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
                      marginBottom: 0,
                      backgroundColor: "#fff",
                      borderRadius: "100%",
                    }}
                  >
                    <img
                      src={croppedImage || "/images/avatar-default.png"}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    sx={{
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      border: "4px solid #fff",
                      width: "35px",
                      height: "35px",
                      backgroundColor: "#D9E2EB",
                      "&:hover": {
                        backgroundColor: "#1688C4",
                      },
                      boxShadow:
                        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                    }}
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <PhotoCameraOutlinedIcon
                      color="black"
                      sx={{ width: "18px", height: "18px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                gap: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={FormIStyled}>
                <TextField
                  color="lightGrey"
                  fullWidth
                  label="ชื่อผู้ใช้"
                  error={!!errors["username"]}
                  helperText={
                    errors["username"] ? errors["username"].message : ""
                  }
                  sx={{ maxWidth: matches ? "248px" : "auto" }}
                  {...register("username", {
                    onChange: debouncedUsernameHandler,
                  })}
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      .toString()
                      .replace(/[^A-Za-z0-9-_]/gi, "")
                      .slice(0, 16);
                  }}
                />

                <TextField
                  color="lightGrey"
                  fullWidth
                  label="รหัสผ่าน"
                  type="password"
                  error={!!errors["password"]}
                  helperText={
                    errors["password"] ? errors["password"].message : ""
                  }
                  sx={{ maxWidth: matches ? "248px" : "auto" }}
                  {...register("password")}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toString().slice(0, 72);
                  }}
                />
              </Box>

              <Box sx={FormIStyled}>
                <TextField
                  color="lightGrey"
                  fullWidth
                  label="เบอร์โทรศัพท์"
                  error={!!errors["phone_no"]}
                  helperText={
                    errors["phone_no"] ? errors["phone_no"].message : ""
                  }
                  sx={{ maxWidth: matches ? "248px" : "auto" }}
                  {...register("phone_no", {
                    onChange: handlePhoneNoChange,
                  })}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toString().slice(0, 10);
                  }}
                />

                <TextField
                  color="lightGrey"
                  fullWidth
                  label="เลขบัตรประชาชน 13 หลัก"
                  error={!!errors["identification_number"]}
                  helperText={
                    errors["identification_number"]
                      ? errors["identification_number"].message
                      : ""
                  }
                  sx={{ maxWidth: matches ? "248px" : "auto" }}
                  {...register("identification_number", {
                    onChange: debouncedIdNumberHandler,
                  })}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toString().slice(0, 13);
                  }}
                />
              </Box>

              <Box sx={FormStyled}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.firstname" />
                    </Typography>
                  </FormLabel>
                  <TextField
                    color="lightGrey"
                    placeholder="ชื่อจริง"
                    error={!!errors["first_name"]}
                    helperText={
                      errors["first_name"] ? errors["first_name"].message : ""
                    }
                    {...register("first_name")}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 100);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.lastname" />
                    </Typography>
                  </FormLabel>
                  <TextField
                    color="lightGrey"
                    placeholder="นามสกุล"
                    error={!!errors["last_name"]}
                    helperText={
                      errors["last_name"] ? errors["last_name"].message : ""
                    }
                    {...register("last_name")}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 100);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.date_of_birth" />
                    </Typography>
                  </FormLabel>

                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      disableFuture={true}
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={date}
                      onChange={(newValue: any) => {
                        const date = dayjs(newValue).format("YYYY-MM-DD");
                        setDate(date);
                        setValue("date_of_birth", date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            onChange: () => {},
                          }}
                          error={!!errors["date_of_birth"]}
                          helperText={
                            errors["date_of_birth"]
                              ? errors["date_of_birth"].message
                              : ""
                          }
                          {...register("date_of_birth")}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>

              <Box sx={FormStyled}>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.gender" />
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    sx={{
                      "&$checked": {
                        color: "#2FD25D",
                      },
                    }}
                    onChange={handleGenderChange}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio color="ktgGreen" />}
                      label="ชาย"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio color="ktgGreen" />}
                      label="หญิง"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.marriage_status" />
                    </Typography>
                  </FormLabel>
                  <Select
                    error={!!errors["type_id"]}
                    {...register("type_id")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกสถานะ"
                          ? {
                              content: `"กรุณาเลือกสถานะ"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                  >
                    {memberType.map((p: { id: number; name: string }) => (
                      <MenuItem key={p.id} value={p.id.toString()}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors["type_id"] ? errors["type_id"].message : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <FormControl fullWidth>
                      <FormLabel>
                        <Typography variant="body1" color="common.black">
                          <FormattedMessage id="register.weight" />
                        </Typography>
                      </FormLabel>
                      <TextField
                        type="text"
                        color="lightGrey"
                        placeholder="กก."
                        value={weight}
                        error={!!errors["weight"]}
                        helperText={
                          errors["weight"] ? errors["weight"].message : ""
                        }
                        {...register("weight", {
                          onChange: handleWeightChange,
                        })}
                        onInput={(e: any) => {
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 3);
                        }}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <FormLabel>
                        <Typography variant="body1" color="common.black">
                          <FormattedMessage id="register.height" />
                        </Typography>
                      </FormLabel>
                      <TextField
                        type="text"
                        color="lightGrey"
                        placeholder="ซม."
                        value={height}
                        error={!!errors["height"]}
                        helperText={
                          errors["height"] ? errors["height"].message : ""
                        }
                        {...register("height", {
                          onChange: handleHeightChange,
                        })}
                        onInput={(e: any) => {
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 3);
                        }}
                      />
                    </FormControl>
                  </Box>
                </FormControl>
              </Box>

              <Box>
                <Divider sx={{ color: "#717171", borderWidth: 2 }}>
                  <Typography variant="h5" color="ktgGreen.main">
                    <FormattedMessage id="register.address" />
                  </Typography>
                </Divider>
              </Box>

              <Box sx={FormStyled}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.current_address1" />
                    </Typography>
                  </FormLabel>
                  <TextField
                    color="lightGrey"
                    error={!!errors["current_address"]}
                    helperText={
                      errors["current_address"]
                        ? errors["current_address"].message
                        : ""
                    }
                    {...register("current_address")}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 200);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.current_moo" />
                    </Typography>
                  </FormLabel>
                  <TextField
                    color="lightGrey"
                    value={moo}
                    error={!!errors["current_moo"]}
                    helperText={
                      errors["current_moo"] ? errors["current_moo"].message : ""
                    }
                    {...register("current_moo", {
                      onChange: handleMooChange,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 50);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.province" />
                    </Typography>
                  </FormLabel>
                  <Select
                    error={!!errors["current_province_id"]}
                    {...register("current_province_id")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกจังหวัด"
                          ? {
                              content: `"กรุณาเลือกจังหวัด"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    onChange={handleProvinceChange}
                  >
                    {provinces.map((p: { id: number; name: string }) => (
                      <MenuItem key={p.id} value={p.id.toString()}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors["current_province_id"]
                      ? errors["current_province_id"].message
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box sx={FormStyled}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.district" />
                    </Typography>
                  </FormLabel>
                  <Select
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกอำเภอ"
                          ? {
                              content: `"กรุณาเลือกอำเภอ"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    error={!!errors["current_district_id"]}
                    {...register("current_district_id")}
                    onChange={handleDistrictChange}
                  >
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors["current_district_id"]
                      ? errors["current_district_id"].message
                      : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.subdistrict" />
                    </Typography>
                  </FormLabel>
                  <Select
                    error={!!errors["current_subdistrict_id"]}
                    {...register("current_subdistrict_id")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกตำบล"
                          ? {
                              content: `"กรุณาเลือกตำบล"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    onChange={handleSubdistrictsChange}
                  >
                    {subdistrict.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors["current_subdistrict_id"]
                      ? errors["current_subdistrict_id"].message
                      : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" color="common.black">
                      <FormattedMessage id="register.postcode" />
                    </Typography>
                  </FormLabel>
                  <Select
                    error={!!errors["current_postcode"]}
                    {...register("current_postcode")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกรหัสไปรษณีย์"
                          ? {
                              content: `"กรุณาเลือกรหัสไปรษณีย์"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                  >
                    {postcodes.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.name}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors["current_postcode"]
                      ? errors["current_postcode"].message
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
                <Button
                  color="blue"
                  variant="outlined"
                  type="button"
                  component={RouterLink}
                  to="/login"
                  sx={{ maxWidth: "230px", width: 1 }}
                >
                  <Typography variant="subtitle2">
                    <FormattedMessage id="common.cancel" />
                  </Typography>
                </Button>

                <Button
                  color="green"
                  variant="contained"
                  type="button"
                  onClick={handlePdpaOpen}
                  disabled={
                    !isDirty ||
                    !gender ||
                    !isValid ||
                    Object.values(errors).length !== 0
                  }
                  sx={{ maxWidth: "230px", width: 1 }}
                >
                  <Typography variant="subtitle2" color="white">
                    <FormattedMessage id="common.save" />
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal keepMounted open={openSuccess}>
        <Box sx={modalStyle} width="340px">
          <RegisterSuccessModal handleFiitPdpaOpen={handleFiitPdpaOpen} />
        </Box>
      </Modal>

      <Dialog keepMounted open={openFiitPdpa}>
        <DialogContent sx={{ height: "calc(100vh - 100px)" }}>
          <DialogContentText>
            <PdpaModal />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: "32px", justifyContent: "center" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={1}
          >
            <FormGroup>
              <FormControlLabel
                onChange={handleAcceptFiitPdpaChange}
                control={<Checkbox color="green" value={true} />}
                label="ยอมรับข้อตกลงและเงื่อนไข"
              />
            </FormGroup>
            <Box display="flex" gap="8px">
              <Button
                color="blue"
                variant="outlined"
                type="button"
                onClick={handleFiitPdpaClose}
              >
                <Typography variant="subtitle2">
                  <FormattedMessage id="register.refuse" />
                </Typography>
              </Button>
              <Button
                color="green"
                variant="contained"
                type="button"
                onClick={handleAcceptFiitPdpaSubmit}
                disabled={!acceptFiitPdpa || loading}
              >
                <Typography variant="subtitle2" color="white">
                  <FormattedMessage id="register.accept" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle} width={340}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>

      <Dialog keepMounted disableEscapeKeyDown open={openPdpa}>
        <DialogContent
          sx={{ height: "calc(100vh - 100px)" }}
          onScroll={handleScroll}
        >
          <DialogContentText>
            <PdapContent consent={consent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button
            color="blue"
            variant="outlined"
            type="button"
            onClick={handlePdpaClose}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="register.refuse" />
            </Typography>
          </Button>
          <Button
            color="green"
            variant="contained"
            type="button"
            onClick={handleSubmit(onSubmitHandler)}
            disabled={loading || !isValid}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="register.accept" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog keepMounted disableEscapeKeyDown open={openCropDialog}>
        <DialogContent sx={{ height: "350px", width: "500px" }}>
          <Box
            position="absolute"
            sx={{ top: 0, left: 0, right: 0, bottom: "80px" }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button
            color="blue"
            variant="outlined"
            type="button"
            onClick={handleCropDialogClose}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="common.cancel" />
            </Typography>
          </Button>
          <Button
            color="green"
            variant="contained"
            type="button"
            onClick={acceptCropAvatar}
          >
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="register.accept" />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RegisterPage;

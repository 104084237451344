import axios from "axios";
import authHeader from "../services/auth-header";
import { memoizedRefreshToken } from "../services/auth.service";
import { removeItem } from "../services/localStorage.service";

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
});

axiosService.interceptors.request.use(
  (config) => {
    config.headers.set(authHeader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const session = await memoizedRefreshToken();

      if (session?.access_token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${session?.access_token}`,
        };
      }

      return axios(config);
    }
    
    return Promise.reject(error);
  }
);

export default axiosService;

import mem from "mem";
import axiosService from "../api/axios";
import { getItem, removeItem, setItem } from "./localStorage.service";

export interface Register {
  type_id: number;
  username: string;
  password: string;
  temp_avatar_filename?: string;
  title?: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  marriage_status: string;
  height: number;
  weight: number;
  phone_no: string;
  email?: string;
  current_address: string;
  current_moo: string;
  current_subdistrict_id: number;
  current_district_id: number;
  current_province_id: number;
  current_postcode: string;
  invited_by?: number;
}

export interface Profile {
  type_id: number;
  temp_avatar_filename?: string;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  gender?: string;
  marriage_status?: string;
  height?: number;
  weight?: number;
  current_address?: string;
  current_moo?: string;
  current_subdistrict_id?: number;
  current_district_id?: number;
  current_province_id?: number;
  current_postcode?: string;
}

interface Login {
  username: string;
  password: string;
}

interface VerifyForget {
  username: string;
  identification_number: string;
  province_id: number;
  district_id: number;
}

interface ResetPassword {
  username: string;
  identification_number: string;
  province_id: number;
  district_id: number;
  new_password: string;
}

interface SelfVerify {
  first_name: string;
  last_name: string;
  identification_number: string;
  date_of_birth: string;
  laser_code: string;
}

export const registerService = (body: Register) => {
  return axiosService.post("/member/signup", body);
};

export const loginService = (body: Login) => {
  return axiosService.post("/auth/login", body);
};

export const logOutService = () => {
  return axiosService.post("/auth/logout");
};

export const verifyUsernameService = (username: string) => {
  return axiosService.post(
    "/member/verify-username?" +
      new URLSearchParams({
        username,
      })
  );
};

export const pdapService = () => {
  return axiosService.get("/member/pdpa");
};

export const consentPdpaService = () => {
  return axiosService.patch("/member/pdpa");
};

export const updateProfileService = (body: Profile) => {
  return axiosService.patch("/member/profile", body);
};

export const profileService = () => {
  return axiosService.get("/member/profile");
};

export const verifyForget = (body: VerifyForget) => {
  return axiosService.post("/member/verify-forget", body);
};

export const ressetPassword = (body: ResetPassword) => {
  return axiosService.post("/member/reset-password", body);
};

export const postSelfVerifyService = (body: SelfVerify) => {
  return axiosService.post("/member/self-verify", body);
};

export const verifyIdentificationNumber = (params: any) => {
  return axiosService.post(
    "/member/verify-identification-number?" + new URLSearchParams(params)
  );
};

const maxAge = 10000;

const refreshTokenFn = async () => {
  const session = getItem("session");

  try {
    const response = await axiosService.post("/auth/refresh-token", {
      refresh_token: session?.refresh_token,
    });

    if (!response?.data?.accessToken) {
      removeItem("session");
    }

    setItem("session", JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    removeItem("session");
    window.location.href = "/login";
  }
};

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});

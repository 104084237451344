import { z } from "zod";

export const registerSchema = z.object({
  username: z.string().min(6).max(16),
  password: z.string().min(6),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  date_of_birth: z.string().min(1),
  identification_number: z.string().min(13).max(13),
  type_id: z.string().min(1),
  height: z.string().min(1),
  weight: z.string().min(1),
  phone_no: z.string().min(10).max(10),
  current_address: z.string().min(1),
  current_moo: z.string(),
  current_subdistrict_id: z.string().min(1),
  current_district_id: z.string().min(1),
  current_province_id: z.string().min(1),
  current_postcode: z.string().min(1),
});

export const profileSchema = z.object({
  identification_number: z.string().min(13).max(13),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  date_of_birth: z.string().min(1),
  type_id: z.string().min(1),
  phone_no: z.string().min(10).max(10),
  current_address: z.string().min(1),
  current_moo: z.string(),
  current_province_id: z.string().min(1),
  current_district_id: z.string().min(1),
  current_subdistrict_id: z.string().min(1),
  current_postcode: z.string().min(1),
});

export const loginSchema = z.object({
  username: z.string().min(6).max(16),
  password: z.string().min(4),
});
